import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ['modal'];

  connect() {
    enter(this.element);

    document.body.addEventListener('click', (e) => {
      if (!this.modalTarget.contains(e.target)) {
        this.close();
      }
    });
  }

  close() {
    leave(this.element);
  }
}
